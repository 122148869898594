import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import classNames from "classnames"
import { telephoneStrip } from "../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt"

const PhoneFAB = () => {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef(null)
  const { locations } = useStaticQuery(
    graphql`
      query {
        locations: allLocationsJson(
          sort: { order: ASC, fields: locationData___order }
        ) {
          nodes {
            locationData {
              phone
              locationName
            }
          }
        }
      }
    `
  )

  const flattened = locations.nodes.map(item => ({
    phone: item.locationData.phone,
    name: item.locationData.locationName
  }))

  const mainClasses = classNames("phone-fab", {
    open: isOpen
  })

  const handleClickOutside = event => {
    if (isOpen) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside, true)
    } else {
      document.removeEventListener("click", handleClickOutside, true)
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [isOpen])

  const handleClick = () => setIsOpen(!isOpen)
  return (
    <div className={mainClasses} ref={containerRef}>
      <div className="phone-fab__phone">
        <div className="phone-fab__heading">
          <p>Contact Us</p> <div className="fab__hline" />
        </div>

        <div className="phone-fab__phones-container">
          {flattened.map(item => (
            <div key={item.name} className="phone-fab__single-phone">
              <h5>{item.name}</h5>
              <p>
                <a
                  href={`tel:+1${telephoneStrip(item.phone)}`}
                  title={`Give us a call at our ${item.name} office`}
                >
                  {item.phone}
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="phone-fab__button" onClick={handleClick}>
        <FontAwesomeIcon icon={faPhoneAlt} />
      </div>
    </div>
  )
}

export default PhoneFAB
