import React from "react"
import { graphql } from "gatsby"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import ReasonsWhy from "./about/ReasonsWhy"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import Seamless from "../components/Seamless"
import PhoneFAB from "../components/PhoneFAB"

const DentistryExperts = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { language } = pageContext

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <PhoneFAB />
        <div className="dentistry-experts-page nav-spacer">
          <div className="dentistry-experts__hero">
            <NuvoImage
              useAR
              wrapperClassName="is-hidden-mobile"
              cloudname="nuvolum"
              publicId={post.hero.image}
              width="auto"
              responsive
            />
            <NuvoImage
              useAR
              wrapperClassName="is-hidden-tablet"
              cloudname="nuvolum"
              publicId={post.hero.imageMobile}
              width="auto"
              responsive
            />
            <div className="dentistry-experts__hero-content">
              <MarkdownViewer markdown={post.hero.heading} />
              <MarkdownViewer markdown={post.hero.blurb} />
            </div>
          </div>

          <div className="dentistry-experts__vline is-hidden-mobile"></div>

          <div className="columns p-section">
            <div className="column is-3"></div>
            <div className="column">
              <MarkdownViewer markdown={post.content} />
            </div>
            <div className="column is-3"></div>
          </div>

          <div className="about-page about-us">
            <ReasonsWhy post={post} language={language} invert={true} />
          </div>

          <div className="columns p-section">
            <div className="column is-5"></div>
            <div className="column">
              <div className="dentistry-experts__form">
                <Seamless src="/ContactForm.js" />
              </div>
            </div>
            <div className="column is-5"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query DentistryExpertsQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          image
          imageMobile
          blurb
        }
        content
        reasonsWhySection {
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
      }
    }
  }
`

export default DentistryExperts
